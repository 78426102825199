import React from "react";
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";
import { Link, NavLink } from 'react-router-dom'
import ReactLoading from "react-loading";
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class CouponRanking extends React.Component {
    state = {
        businessId: "",
        open: false
    }

    componentDidMount() {
        this.props.fetchbussiness();
    }
    deleteUserPermanently() {
        if (this.state.businessId) {
            this.props.businessDelete(this.state.businessId);
            this.close()
        }
    }

    handleOpenDelete = (id) => {
        if (id) {
            this.setState({
                businessId: id,
                open: true
            });
        }
    }

    open() {
        this.setState({ open: true });
    }

    close() {
        this.setState({ open: false });
    }

    handleVerifyStatus =(businessId,status)=>{
        let data = {
            businessId:businessId, 
            status:status === 'verify'?'verify':'notVerify'
        }
        this.props.updateVerifyBusiness(data);
    }


    render() {
        var sno = 1
        let businessList = [];
        let businessTable = <Spinner />;

        {
            this.props.bussinesslisting.length > 0 ?
                this.props.bussinesslisting.map(datas => {

                    businessList.push(
                        {

                            s_no: (sno++),
                            bName: datas.name,
                            bOwnerName: datas.business_owner_name,
                            bPlanName: datas.planType,
                            email: datas.email,
                            city: datas.city
                            ,// datas.city,

                            // datas.country,
                            cInfo: datas.country_code + " " + datas.mobile_no,
                            action: <div>
                                {/* <Link className='btn btn-icon btn-default btn-sm' to='/subbusiness'>
                  <span className='btn-inner--icon'><i className='fas fa-plus' /></span>
                </Link> */}
                                {/* <NavLink className='btn btn-icon btn-default btn-sm' to={'/subbusiness/'+datas._id} >
                      <span className='btn-inner--icon'><i className='fas fa-plus' /></span>
                </NavLink> */}
                                {/* {datas.is_active == true?<button class='btn btn-icon btn-success btn-sm' type='button' 
                onClick={(id) =>this.changeActiveStatus(datas._id)}
                >
                    <span class='btn-inner--icon'><i class='ni ni-check-bold' /></span>
                  </button>:
                  <button class='btn btn-icon btn-danger btn-sm' type='button' 
                  onClick={(id) =>this.changeActiveStatus(datas._id)}
                  >
                    <span class='btn-inner--icon'><i class='ni ni-fat-remove' /></span>
                  </button>}
                   */}
                                <button class='btn btn-icon btn-danger btn-sm' type='button'
                                    onClick={(id) => this.handleOpenDelete(datas._id)}
                                >
                                    <span class='btn-inner--icon'><i class='fas fa-trash' /></span>
                                </button>
                                <NavLink className='btn btn-icon btn-default btn-sm' to={'/edit-couponranking-business/' + datas._id} >
                                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                                </NavLink>
                                {
                                    datas.isVerify ?
                                        <button className='btn btn-icon btn-success btn-sm'
                                            onClick={(id) => this.handleVerifyStatus(datas._id,'notVerify')}
                                        >
                                            <span className='btn-inner--icon'>verified</span>
                                        </button>
                                        :
                                        <button className='btn btn-icon btn-danger btn-sm'
                                            onClick={(id) => this.handleVerifyStatus(datas._id,'verify')}
                                        >
                                            <span className='btn-inner--icon'>Not verified</span>
                                        </button>
                                }

                                <NavLink className='btn btn-icon btn-default btn-sm' to={'/add-coupon-ranking-business-pictures/' + datas._id} >
                                    <span className='btn-inner--icon'><i className='fas fa-plus' /> add pictures</span>
                                </NavLink>
                            </div>

                        }
                    )
                })

                : businessList.push(
                    ["No record"]
                )
        }

        const data = {
            columns: [
                {
                    label: "Sr.No",
                    field: "s_no",
                    sort: "asc",
                },
                {
                    label: "Business Name",
                    field: "bName",
                    sort: "asc",
                },
                {
                    label: "Business Owner Name",
                    field: "bOwnerName",
                    sort: "asc",
                },
                {
                    label: "Business Plan Name",
                    field: "bPlanName",
                    sort: "asc",
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                },
                {
                    label: "Contact Info",
                    field: "cInfo",
                    sort: "asc",
                },
                {
                    label: "City",
                    field: "city",
                    sort: "asc",
                },
                {
                    label: "Action",
                    field: "action",
                    sort: "asc",
                },
            ],
            rows: businessList,

        };

        businessTable = (
            <MDBDataTable striped bordered hover entries={10} data={data} />
        );

        return (
            <div>
                <div className="header bg-primary pb-6">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-center py-4">
                                <div className="col-lg-6 col-7">
                                    <h6 className="h2 text-white d-inline-block mb-0">
                                        Coupon Ranking Business User
                                    </h6>
                                </div>
                            </div>
                            <Dialog
                                open={this.state.open}
                                onClose={() => this.close()}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Do you want to delete Business Owner?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">

                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <button class='btn btn-icon btn-danger btn-sm' type='button' onClick={() => this.close()}>
                                        No
                                    </button>
                                    <button class='btn btn-icon btn-success btn-sm' onClick={() => this.deleteUserPermanently()} type='button'>
                                        Yes
                                    </button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt--6">
                    <div className="card">
                        <div className='card-header border-0'>
                            <div className='text-right'>
                                <Link className='btn btn-primary text-white' to='/coupon-ranking-details'><i className="fas fa-plus"></i> Add Business</Link>
                            </div>
                        </div>
                        <div className="table-responsive px-4">
                            <table className="table align-items-center table-flush">
                                {businessTable}
                            </table>
                        </div>
                        {
                            this.props.loading ?
                                <div className='loader-wrapper'>
                                    <ReactLoading type="spin" color="#0074d9" className='loader-spiner' height={100} width={70} />
                                </div> : ''
                        }
                    </div>

                    <footer className="footer pt-0">
                        <div className="row align-items-center justify-content-lg-between">
                            <div className="col-lg-6">
                                <div className="copyright text-center text-lg-left  text-muted mt-4 ml-4">
                                    &copy; 2021 ServiceHubConnect
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

// fetchcouponbussinessListAction

const mapPropsToState = (state) => {
    return {
        success: state.couponRanking.success,
        error: state.couponRanking.error,
        loading: state.couponRanking.loading,
        bussinesslisting: state.couponRanking.bussinesslisting
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchbussiness: () => dispatch(actions.fetchcouponbussinessListAction()),
        businessDelete: (data) => dispatch(actions.deleteCouponRankingBusinessUser(data)),
        updateVerifyBusiness: (data) => dispatch(actions.updateVerifyBusiness(data)),
    };
};

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(CouponRanking));
