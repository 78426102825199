import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import Header from './Header'
import Sidebar from './Sidebar'
import Dashboard from './Dashboard'
import Professionals from './Professionals'
import AddProfessionals from './AddProfessionals'
import EditProfessionals from './EditProfessionals'
import Business from './Business'
import AddBusiness from './AddBusiness'
import Customer from './Customer'
import BusinessDetailList from './BusinessDetailList'
import BusinessCategory from './BusinessCategory'
import Orders from './Orders'
import OrdersDetails from './OrdersDetails'
import OrdersCurrentService from './OrdersCurrentService'
import OrdersHistory from './OrdersHistory'
import BusinessSubCategory from './BusinessSubCategory'
import Reports from './Reports'
import ReportsProfessional from './ReportsProfessional'
import ReportsDetail from './ReportsDetail'
import AdminUser from './AdminUser'
import AddAdminUser from './AddAdminUser'
import Settings from './Settings'
import AddCity from './AddCity'
import AboutUs from './AboutUs'
import Subscription_list from './Subscription_list'
import PrivacyPolicy from './PrivacyPolicy'
import TermAndCondition from './TermAndCondition'
import ContactUs from './ContactUs'
import FirstOrderDiscount from './FirstOrderDiscount'
import PromoCode from './PromoCode'
import Subscription from './Subscription'
import AddManageProfile from './AddManageProfile'
import OrdersDetailsRideShare from './OrdersDetailsRideShare'
import AddPictures from './AddPictures'
import AddCouponRankingBusinessPicture from './AddCouponRankingBusinessPicture'
import AddCategoryMedia from './AddCategoryMedia'
import Advertisement from './Advertisement'
import AdverismentPicture from './AdvPicture'
import AdverismentVideo from './AdvVideo'
import AdvertiseSubscription from './AdvertiseSubscription'
import AddAdvSubscriptionPlan from './AddAdvSubscriptionPlan'
import EditAdvSubscriptionPlan from './EditAdvSubscriptionPlan'
import ReportCustomer from './ReportCustomer'
import AdvSubscribedUserList from './subscribedBusinessUserList'
import BlogList from './BlogList'
import CreateBlog from './CreateBlog'
import BlogDetails from './BlogDetails'
import BlogAndPriceAdPicture from './BlogAndPriceAdPicture'
import BlogAndPriceAdVideo from './BlogAndPriceAdVideo'
import CouponRanking from './CouponRanking'
import CouponRankingDetails from './CouponRankingDetails'
import EditCouponrankingBusiness from './EditCouponrankingBusiness'
import CouponRankingReport from './CouponRankingReport'

export default function Master() {
  return (
    <Router>
      <Sidebar />
      <div class='main-content' id='panel'>
        <Header />
        <Switch>
          <Route path='/add-pictures/:id'> <AddPictures /></Route>
          <Route path='/add-coupon-ranking-business-pictures/:id'> <AddCouponRankingBusinessPicture /></Route>
          <Route path='/edit-couponranking-business/:id'><EditCouponrankingBusiness /></Route>
          <Route path='/advertisement'> <Advertisement /></Route>
          <Route path='/advertisement-picture/:id'> <AdverismentPicture /></Route>
          <Route path='/advertisement-video/:id'> <AdverismentVideo /></Route>
          <Route path='/blog-price-ad-picture/:id'> <BlogAndPriceAdPicture /></Route>
          <Route path='/blog-price-ad-video/:id'> <BlogAndPriceAdVideo /></Route>
          <Route path='/advertise-subscription'> <AdvertiseSubscription /></Route>
          <Route path='/add-advertise-subscription'> <AddAdvSubscriptionPlan /></Route>
          <Route path='/edit-advertise-subscription/:id'> <EditAdvSubscriptionPlan /></Route>
          <Route path='/get-advertise-subscribed-Business-user-list'> <AdvSubscribedUserList /></Route>
          <Route path='/blogs'> <BlogList /></Route>
          <Route path='/create-blog'> <CreateBlog /></Route>
          <Route path='/blog-details/:id' render={(props) => <BlogDetails {...props} />}></Route>
          <Route path='/coupon-ranking'> <CouponRanking /></Route>
          <Route path='/coupon-ranking-details'> <CouponRankingDetails /></Route>
          <Route path='/coupon-ranking-reports'> <CouponRankingReport /></Route>
          <Route path='/add-category-media/:id'> <AddCategoryMedia /></Route>
          <Route path='/add-manage-profile'> <AddManageProfile /></Route>
          <Route path='/addadminuser'> <AddAdminUser /></Route>
          <Route path='/editadminuser/:id' render={(props) => <AddAdminUser {...props} />} ></Route>
          <Route path='/adminuser'> <AdminUser /></Route>
          <Route path='/subscription'> <Subscription /></Route>
          <Route path='/promocode'> <PromoCode /></Route>
          <Route path='/firstorderdiscount/:id' render={(props) => <FirstOrderDiscount {...props} />} ></Route>
          <Route path='/contactus'> <ContactUs /></Route>
          <Route path='/termandcondition'> <TermAndCondition /></Route>
          <Route path='/privacypolicy'> <PrivacyPolicy /></Route>
          <Route path='/aboutus'> <AboutUs /></Route>
          <Route path='/subscription_list'> <Subscription_list /></Route>
          <Route path='/addcity'> <AddCity /></Route>
          <Route path='/addcity/:id' render={(props) => <AddCity {...props} />}></Route>
          <Route path='/settings'> <Settings /></Route>
          <Route path='/reportsdetail/:id' render={(props) => <ReportsDetail {...props} />}></Route>
          <Route path='/reportsprofessional/:id' render={(props) => <ReportsProfessional {...props} />}></Route>
          <Route path='/reportcustomer/:id' render={(props) => <ReportCustomer {...props} />}></Route>
          <Route path='/reports'> <Reports /></Route>
          <Route path='/orderhistory'> <OrdersHistory /></Route>
          <Route path='/currentservice'> <OrdersCurrentService /></Route>
          <Route path='/orderdetails'> <OrdersDetails /></Route>
          <Route path='/getorderdetails/:id' render={(props) => <OrdersDetails {...props} />}></Route>
          <Route path='/getorderdetailsRideShareDelivery/:id' render={(props) => <OrdersDetailsRideShare {...props} />}></Route>
          <Route path='/orders'> <Orders /></Route>
          <Route path='/businesscategory'> <BusinessCategory /></Route>
          <Route path='/businesssubcategory/:id/:name' render={(props) => <BusinessSubCategory {...props} />} />
          {/* <Route path='/subbusiness'> <BusinessDetailList /></Route> */}
          <Route path='/subbusiness/:id' render={(props) => <BusinessDetailList {...props} />} />
          <Route path='/customer'> <Customer /></Route>
          <Route path='/addbusiness'> <AddBusiness /></Route>
          <Route path='/editbusiness/:id' render={(props) => <AddBusiness {...props} />} />
          <Route path='/business'> <Business /></Route>
          <Route path='/editprofessional/:id' render={(props) => <EditProfessionals {...props} />} />
          <Route path='/addprofessionals'> <AddProfessionals /></Route>
          {/* <Route path='/addprofessionals/:id' render={(props) => <AddProfessionals {...props} />} /> */}
          <Route path='/professionals'> <Professionals /></Route>
          <Route path='/'><Dashboard /></Route>
        </Switch>
      </div>
    </Router>
  )
}
