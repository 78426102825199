import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  businessPicture: [],
  businessVideo:[],
  progress:0,
  // pictureProgress:0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_BUSSINESS_MEDIA:
      return {
          ...state,
          loading:true,
          error:null
      };
    case actionTypes.BUSSINESS_MEDIA_FAIL :
        return {
            ...state,
            loading:null,
            error:action.message,
            success:null,
        }
    case actionTypes.GET_BUSINESS_MEDIA_SUCCESS :
      return {
          ...state,
          loading:null,
          businessPicture: action.businessPicture,
          businessVideo:action.businessVideo,
          error:null,
          success:action.message,
          
      }
      case actionTypes.BUSSINESS_PICTURE_UPLOAD_SUCCESS :
        return {
            ...state,
            loading:null,
            businessPicture: action.businessPicture,
            error:null,
            success:action.message,
            
        }

        case actionTypes.BUSSINESS_PICTURE_DELETE_SUCCESS :
        return {
            ...state,
            loading:null,
            businessPicture: action.businessPicture,
            error:null,
            success:action.message,
            
        }
        case actionTypes.BUSSINESS_VIDEO_UPLOAD_SUCCESS :
        return {
            ...state,
            loading:null,
            businessVideo: action.businessVideo,
            error:null,
            success:action.message,
            
        }
        case actionTypes.BUSSINESS_VIDEO_DELETE_SUCCESS :
        return {
            ...state,
            loading:null,
            businessVideo: action.businessVideo,
            error:null,
            success:action.message,
            
        }
        case actionTypes.PROGRESS_BAR :
        return {
            ...state,
            loading:null,
            error:null,
            progress:action.progress,
            
        }
        // case actionTypes.PICTURE_PROGRESS :
        //   return {
        //       ...state,
        //       loading:null,
        //       error:null,
        //       pictureProgress:action.progress,
              
        //   }
    default:
      return state;
  }
};



export default reducer;
